// Rules AUTOMATIC DISCOUNT
// 1. If 4 plans_contract is added with 3 days of frequency in each ==> 10% discount total
// 2. If 3 plans_contract is added with 3 days of frequency in each ==> 8% discount total
// 3. If 1 Exists, 2 does not exisdt
// 4. If client has a contract with another student register ==> Add 5% discount

import moment from 'moment'

// '['hasBrother','MoreThan3Plans','MoreThan4Plans']
export type TDiscountAdd = 'hasBrother' | 'moreThan3Plans' | 'moreThan4Plans'

export const messagesPlanDiscount = {
  hasBrother:
    'Um desconto de 10% foi adicionado por estar contratando o plano para outro estudante.',
  moreThan3Plans:
    'Um desconto de 8% foi adicionado por estar contratando mais de 3 planos, com frequência de 3 dias por semana no mínimo.',
  moreThan4Plans:
    'Um desconto de 10% foi adicionado por estar contratando mais de 4 planos, com frequência de 3 dias por semana no mínimo'
}
export interface IAutomaticDiscount {
  discounts_add?: TDiscountAdd[]
  automaticDiscountAdded?: number
}
const verifyIfPlanHasMoreThanThreeDaysOfFrequency = (
  plans_contract: IContractPlanDisplay[],
  numberOfPlans: number
) => {
  return (
    plans_contract.filter(plan => plan.frequency_days_of_week.length >= 3)
      .length >= numberOfPlans
  )
}

export const calculateAutomaticDiscount = (
  plans_contract: IContractPlanDisplay[],
  client: IClientData,
  actualStudent: IStudent
): IAutomaticDiscount => {
  let automaticDiscountAdded = 0
  const discounts_add: TDiscountAdd[] = []

  if (actualStudent.school_period === 'Integral') {
    if (plans_contract.length >= 4) {
      if (verifyIfPlanHasMoreThanThreeDaysOfFrequency(plans_contract, 4)) {
        automaticDiscountAdded += 10
        discounts_add.push('moreThan4Plans')
      }
    } else if (plans_contract.length === 3) {
      if (verifyIfPlanHasMoreThanThreeDaysOfFrequency(plans_contract, 3)) {
        automaticDiscountAdded += 8
        discounts_add.push('moreThan3Plans')
      }
    }
  }

  const currentPeriod =
    process.env.REACT_APP_CURRENT_PERIOD_START_DATE || '2025-01-09'
  // Verificar no banco de dados os contratos realizados por esse cliente, e o estudante do contrato.
  const actualStudentsContract = client.contracts
    .filter(
      c =>
        (c.situation === 'Ativo' || c.situation === 'Aguardando pagamento') &&
        moment(c.start_date, 'DD/MM/YYYY HH:mm:ss').isSameOrAfter(currentPeriod)
    )
    .map(e => e.student_id)
    .filter(e => e !== actualStudent.id)
  const uniqueCharsContract = Array.from(new Set(actualStudentsContract)).length
  if (uniqueCharsContract > 0) {
    automaticDiscountAdded += 10
    discounts_add.push('hasBrother')
  }

  return {
    automaticDiscountAdded,
    discounts_add
  }
}
