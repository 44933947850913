import { useEffect, useState } from 'react'
import api from '../../../../../../services/api'
import Form, { Input, Select } from '../../../../../../components/Form'
import { useToast } from '../../../../../../hooks/toast'
import { useLoading } from '../../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { FormContainer, IconRemove } from './styles'
import { DateInput as DatePicker } from '../../../../../../components/Form/date'
import { deleteEmptyFields } from 'utlis/deleteEmptyFields'
import { GENDERS, SCHOOL_PERIODS } from 'commons/constants/commercial'
import { apiGetStudent } from 'pages/Commercial/UserContracts/domain/api'
import { cpfMask } from '../../../../../../utlis/mask'
import { validate } from 'gerador-validador-cpf'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

type TypesFormProps = {
  initialValues?: {
    institution_id: number
    institution_name: string
    institution_series_classes: ISeriesClasses[]
  }
  typeForm: 'create' | 'update'
  clientInfo: {
    id: number
    name: string
  }
  onSubmit?: (data: any) => any
  setStudent?: React.Dispatch<React.SetStateAction<IStudent>>
  studentList?: IStudent[]
  updateOnSubmit: () => void
}

export const FormStudent = ({
  initialValues,
  typeForm,
  updateOnSubmit,
  onSubmit,
  setStudent,
  studentList,
  clientInfo
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const { updateDataTable } = useUpdateDataTable()
  const history = useHistory()
  const [defaultValues, setDefaultValues] = useState<any>()
  const [selectedStudent, setSelectedStudent] = useState('')
  const [hasUpdated, setHasUpdated] = useState<boolean>(false)
  const [birthDate, setBirthDate] = useState<Date | string>()
  const [cpf, setCpf] = useState<string>()

  const [schoolPeriod, setSchoolPeriod] = useState('')
  const [currentSchoolPeriods, setCurrentSchoolPeriods] = useState<string[]>([])
  const [seriesClasses, setSeriesClasses] = useState<
    { name: string; value: number; periods: any[] }[]
  >([])

  const [cpfError, setCpfError] = useState<{
    message?: string
    error: boolean
  }>({ message: '', error: false })

  const [studentFoodRestrictions, setStudentFoodRestrictions] = useState<
    Omit<IStudentFoodRestriction, 'created_at' | 'updated_at'>[]
  >([
    {
      id: 0,
      description: '',
      student_id: undefined
    }
  ])

  const addStudentFoodRestriction = () => {
    setStudentFoodRestrictions(old => [
      ...old,
      {
        id: old[old.length - 1]?.id + 1 || 1,
        description: ''
      }
    ])
  }
  const removeOneStudentFoodRestriction = (idToDelete: number) => {
    setStudentFoodRestrictions(old => {
      return old.filter(({ id }) => id !== idToDelete)
    })
  }

  useEffect(() => {
    if (initialValues && !hasUpdated) {
      /* if (initialValues?.birth_date) {
        // moment to date ver tipos de data
        initialValues.birth_date = moment(
          initialValues?.birth_date,
          'DD/MM/YYYY'
        ).toDate()
      } */
      // setStudentFoodRestrictions(initialValues.student_food_restrictions)
      setDefaultValues({
        ...initialValues,
        school_period: '',
        serie_class_id: ''
      })

      if (initialValues.institution_series_classes) {
        const seriesClasses: { name: string; value: number; periods: any[] }[] =
          []
        initialValues.institution_series_classes.forEach(serieClass => {
          const hasSerieClass = seriesClasses.find(
            b => b.name === serieClass.school_year
          )
          if (!hasSerieClass) {
            const newOne = {
              name: serieClass.school_year,
              value: serieClass.id,
              periods: [serieClass.school_period]
            }
            seriesClasses.push(newOne)
          } else {
            hasSerieClass.periods.push(serieClass.school_period)
          }
        })
        setSeriesClasses(seriesClasses)
      }
    }
  }, [initialValues, hasUpdated])

  const checkCpf = (cpf: string) => {
    setCpf(cpfMask(cpf))
    if (cpf.length === 14) {
      if (validate(cpf)) {
        setCpfError({
          error: false,
          message: 'CPF válido'
        })
      } else {
        setCpfError({
          error: true,
          message: 'CPF inválido'
        })
      }
    }
  }

  const { activeLoading, disableLoading } = useLoading()
  const onSubmitForm = async (dataForm: IStudent) => {
    if (cpfError.error) {
      window.scrollTo(0, 0)
      return
    }
    const data = deleteEmptyFields(dataForm)
    data.institution_id = initialValues.institution_id
    data.institution_name = undefined
    data.client_id = clientInfo.id
    // remove restrictionFood input names
    for (const key in data) {
      if (key.includes('input_food_restriction')) {
        delete data[key]
      }
    }

    data.student_food_restrictions = studentFoodRestrictions.map(
      ({ description }) => ({
        description
      })
    )
    const path = history.location.pathname
    try {
      if (selectedStudent === 'new_student' || !studentList?.length) {
        try {
          const dataCreate = {
            ...data,
            school_period: schoolPeriod
          }
          activeLoading()
          const studentCreateResponse = await api.post(apiCreate(), dataCreate)
          const studentGetResponse = await api.get(
            apiGetStudent(String(studentCreateResponse.data.id))
          )
          setStudent(studentGetResponse.data)
          history.replace(
            `${path}?client=${clientInfo.id}&student=${studentGetResponse.data.id}&step=3`
          )
          updateDataTable()
          updateOnSubmit()

          disableLoading()
          addToast({
            type: 'success',
            title: 'Estudante criado',
            description: 'Estudante criado com sucesso'
          })
          onSubmit(undefined)
        } catch (err: any) {
          let description =
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
          if (!err?.response?.data?.message?.includes('Internal server')) {
            description = err?.response?.data?.message
          }
          addToast({
            type: 'error',
            title: 'Erro ao adicionar o estudante',
            description
          })
          disableLoading()
        }
      } else {
        const dataUpdate = {
          ...data,
          school_period: schoolPeriod
        }
        try {
          activeLoading()
          await api.put(apiUpdate(String(defaultValues.id)), dataUpdate)
          const studentGetResponse = await api.get(
            apiGetStudent(String(defaultValues.id))
          )
          setStudent(studentGetResponse.data)
          history.replace(
            `${path}?client=${clientInfo.id}&student=${studentGetResponse.data.id}&step=3`
          )
          updateDataTable()
          updateOnSubmit()

          disableLoading()
          addToast({
            type: 'success',
            title: 'Estudante selecionado',
            description: 'Estudante selecionado com sucesso'
          })
          onSubmit(undefined)
        } catch (err: any) {
          let description =
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
          if (!err?.response?.data?.message?.includes('Internal server')) {
            description = err?.response?.data?.message
          }

          addToast({
            type: 'error',
            title: 'Erro ao atualizar o estudante',
            description
          })
        }
      }
      disableLoading()
    } catch (err) {
      console.log(err)
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
      }
    }
  }

  return (
    <>
      <div>
        <h4 style={{ marginBottom: '30px' }}>
          Escola - {initialValues.institution_name}
        </h4>
        {studentList && studentList?.length !== 0 && (
          <p style={{ marginBottom: '15px' }}>Selecione um estudante:</p>
        )}
        {studentList?.map(student => {
          const pending = student?.contracts?.some(contract =>
            ['Aguardando pagamento', 'Ativo'].includes(contract.situation)
          )
          return (
            <div key={student.id}>
              <input
                type="radio"
                name="student"
                id={`student-${student.id}`}
                // disabled={pending}
                value={student.name}
                checked={selectedStudent === student.name}
                onChange={e => {
                  setSelectedStudent(e.target.value)
                  setDefaultValues({
                    ...student,
                    birth_date: moment(student.birth_date, 'DD/MM/YYYY')
                      .add(3, 'h')
                      .toDate(),
                    school_year: '',
                    school_period: '',
                    serie_class_id: ''
                  })
                  const currentSerieClass = seriesClasses.find(
                    serieClass =>
                      String(serieClass.name) ===
                      String(student.serie_class.school_year)
                  )
                  if (currentSerieClass) {
                    setCurrentSchoolPeriods(currentSerieClass.periods)
                  }

                  setStudentFoodRestrictions(student.student_food_restrictions)
                }}
              />
              <label
                htmlFor={`student-${student.id}`}
                style={{ marginLeft: '10px' }}
              >
                {student.name}
                {/* {pending && (
                  <span style={{ color: 'red', marginLeft: '10px' }}>
                    (O estudante já possui um contrato em aberto. Para um novo
                    contrato, solicite o cancelamento com nossa equipe. Whatsapp
                    - (31) 99118-5816)
                  </span>
                )} */}
              </label>
            </div>
          )
        })}
        {studentList && studentList?.length !== 0 && (
          <>
            <p style={{ margin: '10px 0px' }}>OU:</p>
            <button
              type="button"
              id="new_student"
              className="btn btn-primary"
              style={{ padding: '5px 8px', marginBottom: '20px' }}
              onClick={() => {
                setSelectedStudent('new_student')
                setDefaultValues({
                  name: '',
                  gender: '',
                  school_year: '',
                  school_period: '',
                  school_room: '',
                  birth_date: undefined,
                  observation: '',
                  serie_class_id: ''
                })
                setSchoolPeriod('')
              }}
            >
              Adicionar novo
            </button>
          </>
        )}
      </div>
      <Form onSubmit={onSubmitForm} setReset defaultValues={defaultValues}>
        <div
          style={{
            display: selectedStudent || !studentList?.length ? 'block' : 'none'
          }}
        >
          <div className="mb-5 mb-xl-10">
            <FormContainer className="form-body">
              <div className="row mb-5">
                <Input
                  name="name"
                  className=" col-md-6"
                  label="Nome"
                  rules={{ required: true, position: 'left' }}
                />
                <Input
                  name="cpf"
                  className=" col-md-2"
                  label="CPF"
                  value={cpf}
                  rules={{ required: true, position: 'left' }}
                  maxLength={14}
                  onChange={event => checkCpf(event.target.value)}
                  hasError={{
                    message: cpfError.message,
                    error: cpfError.error
                  }}
                />
                <Select
                  name="gender"
                  className="col-md-3"
                  label="Sexo"
                  rules={{ required: true }}
                  options={GENDERS}
                  blank
                  defaultValue={''}
                />
                <DatePicker
                  label="Data de nascimento"
                  className="col-md-3"
                  name="birth_date"
                  rules={{ required: true }}
                  value={birthDate as string}
                  selected={
                    (birthDate as Date) ||
                    (defaultValues?.birth_date as Date) ||
                    undefined
                  }
                  onChange={date => setBirthDate(date)}
                  maxDate={new Date()}
                  calendarContainer={() => (
                    <div style={{ display: 'none' }}></div>
                  )}
                  refuseDateAfterToday
                  controlled
                />
                <Select
                  name="serie_class_id"
                  className="col-md-3"
                  label="Ano/Turma"
                  rules={{ required: true }}
                  options={
                    seriesClasses?.map(isc => ({
                      name: isc.name,
                      value: Number(isc.value)
                    })) || []
                  }
                  onChange={({ target }) => {
                    const currentSerieClass = seriesClasses.find(
                      serieClass =>
                        String(serieClass.value) === String(target.value)
                    )
                    if (currentSerieClass) {
                      setCurrentSchoolPeriods(currentSerieClass.periods)
                      setSchoolPeriod('')
                    }
                  }}
                  blank
                  defaultValue={''}
                />
                <Select
                  name="school_period"
                  className="col-md-3"
                  label="Turno"
                  rules={{ required: true }}
                  options={currentSchoolPeriods.map(value => ({
                    name: value,
                    value
                  }))}
                  value={schoolPeriod}
                  onChange={({ target }) => {
                    setSchoolPeriod(target.value)
                  }}
                  blank
                  defaultValue={''}
                />
                {typeForm === 'update' && (
                  <Select
                    className=" col-md-2"
                    name="is_active"
                    label="Ativo"
                    options={[
                      {
                        name: 'Sim',
                        value: 'true'
                      },
                      {
                        name: 'Não',
                        value: 'false'
                      }
                    ]}
                    blank
                    defaultValue={'true'}
                    rules={{ required: true }}
                  />
                )}
              </div>
            </FormContainer>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="submit" className="btn btn-primary">
                Próximo
              </button>
            </div>
          </div>
        </div>
      </Form>
    </>
  )
}
